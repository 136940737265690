var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid new-list"},[_c('v-dialog',{attrs:{"width":"1500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline "},[_c('v-row',[_c('v-col',{attrs:{"md":"8"}},[_c('v-text-field',{attrs:{"placeholder":"Tên mục lục","outlined":"","dense":""},model:{value:(_vm.categoriesName),callback:function ($$v) {_vm.categoriesName=$$v},expression:"categoriesName"}})],1),_c('v-col',{attrs:{"md":"4"}},[(_vm.btnCategories)?_c('v-btn',{on:{"click":_vm.createCategoriesNews}},[_vm._v(" Tạo mục lục ")]):_c('div',[_c('v-btn',{on:{"click":_vm.editCategories}},[_vm._v(" Lưu mục lục ")]),_c('v-btn',{staticClass:"ml-3",on:{"click":function($event){_vm.btnCategories = true;_vm.categoriesName=''}}},[_vm._v(" Hủy bỏ ")])],1)],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headersCategories,"items":_vm.categories},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","fab":"","x-small":"","color":"teal"},on:{"click":function($event){return _vm.getDetailNewsByCategories(item.id)}}},[_c('v-icon',[_vm._v("mdi-eye")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","fab":"","x-small":"","color":"teal"},on:{"click":function($event){return _vm.getCategoriesById(item.id)}}},[_c('v-icon',[_vm._v(" mdi-lead-pencil")])],1),_c('v-btn',{staticClass:"ma-2",attrs:{"outlined":"","fab":"","x-small":"","color":"red"},on:{"click":function($event){return _vm.deleteCategories(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1),_c('v-divider'),_c('v-card-actions')],1)],1),_c('div',{staticClass:"create-btn"},[_c('v-btn',{attrs:{"width":"150px","color":"red lighten-2 mr-5","dark":""},on:{"click":function($event){_vm.dialog = true}}},[_vm._v(" Mục lục bài viết ")]),_c('v-btn',{staticClass:"mr-5",attrs:{"elevation":"2"},on:{"click":_vm.goToForm}},[_vm._v(_vm._s(_vm.language.title))]),(_vm.btnGetList)?_c('v-btn',{attrs:{"width":"300px","color":"blue lighten-1","dark":""},on:{"click":_vm.getListNews}},[_vm._v(" Lấy tất cả danh sách bài viết ")]):_vm._e()],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.list,"item-key":"name"},scopedSlots:_vm._u([{key:"item.action3",fn:function(ref){
var item = ref.item;
return [_c('select',{directives:[{name:"model",rawName:"v-model",value:(item.status),expression:"item.status"}],staticClass:"custom-select",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(item, "status", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.ChangeTopExam(item)}]}},[_c('option',{attrs:{"value":"0"}},[_vm._v("Chưa hoàn thành")]),_c('option',{attrs:{"value":"1"}},[_vm._v("Hoàn thành")])])]}},{key:"item.action2",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{on:{"change":function($event){return _vm.ChangeTopExam(item)}},model:{value:(item.top),callback:function ($$v) {_vm.$set(item, "top", $$v)},expression:"item.top"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.goUpdateForm(item.id)}}},[_vm._v(" mdi-lead-pencil ")]),_c('img',{attrs:{"src":require("../../../assets/images/eye_icon.png"),"height":"11","width":"17"},on:{"click":function($event){return _vm.getNewsInfo(item.id)}}}),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }