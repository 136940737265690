<template>
  <div class="container-fluid new-list">
    <v-dialog v-model="dialog" width="1500">
      <v-card>
        <v-card-title class="headline ">
          <v-row>
            <v-col md="8">
              <v-text-field
                placeholder="Tên mục lục"
                outlined
                dense
                v-model="categoriesName"
              ></v-text-field>
            </v-col>
            <v-col md="4">
              <v-btn v-if="btnCategories" v-on:click="createCategoriesNews">
                Tạo mục lục
              </v-btn>
              <div v-else>
                <v-btn  v-on:click="editCategories">
                  Lưu mục lục
                </v-btn>
                <v-btn class="ml-3" v-on:click="btnCategories = true;categoriesName=''">
                  Hủy bỏ
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-data-table :headers="headersCategories" :items="categories">
            <template v-slot:item.actions="{ item }">
              <v-btn class="ma-2" outlined fab x-small color="teal" v-on:click="getDetailNewsByCategories(item.id)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn
                class="ma-2"
                outlined
                fab
                x-small
                color="teal"
                v-on:click="getCategoriesById(item.id)"
              >
                <v-icon> mdi-lead-pencil</v-icon>
              </v-btn>
              <v-btn
                class="ma-2"
                outlined
                fab
                x-small
                color="red"
                v-on:click="deleteCategories(item.id)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="create-btn">
      <v-btn width="150px" color="red lighten-2 mr-5" dark @click="dialog = true">
        Mục lục bài viết
      </v-btn>
      <v-btn elevation="2" @click="goToForm" class="mr-5">{{ language.title }}</v-btn>
      <v-btn width="300px" color="blue lighten-1" dark @click="getListNews" v-if="btnGetList">
        Lấy tất cả danh sách bài viết
      </v-btn>
    </div>
    <v-data-table
      :headers="headers"
      :items="list"
      item-key="name"
      class="elevation-1"
    >
      <template v-slot:item.action3="{ item }">
          <select class="custom-select" v-model="item.status" @change="ChangeTopExam(item)">
            <option value="0">Chưa hoàn thành</option>
            <option value="1">Hoàn thành</option>
          </select>
      </template>
      <template v-slot:item.action2="{ item }">
        <v-switch
            v-model="item.top"
            @change="ChangeTopExam(item)"
        ></v-switch>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="goUpdateForm(item.id)">
          mdi-lead-pencil
        </v-icon>
        <img
          @click="getNewsInfo(item.id)"
          src="../../../assets/images/eye_icon.png"
          height="11"
          width="17"
        />
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  GET_LIST_NEW,
  DELETE_NEW,
  GET_LIST_CATEGORIES,
  SAVE_CATEGORIES,
  UPDATE_CATEGORIES,
  UPDATE_NEW,
  GET_DETAIL_NEWS,
  DELETE_CATEGORIES,
  GET_DETAIL_CATEGORIES
} from "@/store/new.module";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  //list news
  data() {
    return {
      language: {
        title: "Thêm bài viết"
      },
      dialog: false,
      selected: [],
      headers: [
        { text: "Tên bài viết", value: "title" },
        { text: "Trạng thái", value: "action3" },
        { text: "Hiển thị", value: "action2" },
        { text: "Người đăng", value: "user_full_name" },
        { text: "Thời gian", value: "date" },
        { text: "Actions", value: "actions", sortable: false }
      ],
      editedIndex: -1,
      editedItem: {
        title: "",
        slug: 0,
        user_id: 0,
        date: 0,
        actions: 0
      },
      headersCategories: [
        { text: "ID", value: "id" },
        { text: "Tiêu đề mục lục", value: "title" },
        { text: "Hành động", value: "actions", sortable: false }
      ],
      categoriesName: "",
      btnCategories:true,
      idCategories : "",
      btnGetList:false
    };
  },

  created() {
    this.getListNews();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Bài viết", titledad:'Bài viết' }]);
    this.$store.dispatch(GET_LIST_CATEGORIES, 0);
  },

  computed: {
    ...mapGetters({
      list: "getListNew",
      categories: "getListCategories",
      detailCategories: "getDetailCategories",
    })
  },

  methods: {
    ChangeTopExam(item) {
      item.top = item.top == true ? 1 : 0;
      var data = {
        id: item.id,
        slug: item.slug,
        top: item.top,
        status:item.status
      };
      this.$store.dispatch(UPDATE_NEW, data);
      this.$toasted.success('Lưu thành công', {
        position: "top-right",
        theme: "toasted-primary",
        duration: 1000
      });

    },
    async getListNews(){
      await this.$store.dispatch(GET_LIST_NEW, 0);
      this.btnGetList = false;
    },
    async createCategoriesNews() {
      await this.$store.dispatch(SAVE_CATEGORIES, {
        title: this.categoriesName,
        slug: this.sanitizeTitle(this.categoriesName)
      });
      this.categoriesName = "";
      await this.$store.dispatch(GET_LIST_CATEGORIES, 0);
    },
    async editCategories() {
      await this.$store.dispatch(UPDATE_CATEGORIES, { id:this.idCategories , title:this.categoriesName , slug: this.sanitizeTitle(this.categoriesName)});
      this.categoriesName = "";
      this.btnCategories = true;
      await this.$store.dispatch(GET_LIST_CATEGORIES, 0);
    },
    async deleteCategories(id) {
      console.log(id)
      if (confirm("Bạn muốn xóa bài viết?")) {
        await this.$store.dispatch(DELETE_CATEGORIES, id);
        await this.$store.dispatch(GET_LIST_CATEGORIES, 0);
      }
    },
    async getCategoriesById(id) {
      await this.$store.dispatch(GET_DETAIL_CATEGORIES, id);
      if (this.detailCategories) {
        this.categoriesName = this.detailCategories.title;
        this.idCategories = this.detailCategories.id;
        this.btnCategories = false;
      }
    },
    async getNewsInfo(id) {
      this.$router.push({
        path: `/new/detail`,
        query: {
          news_id: id
        }
      });
    },

    goToForm() {
      this.$router.push({ path: "/new/create" });
    },

    async goUpdateForm(id) {
      this.$router.push({
        path: "/new/form",
        query: {
          news_id: id
        }
      });
    },

    async deleteItem(item) {
      if (confirm("Bạn muốn xóa bài viết?")) {
        await this.$store.dispatch(DELETE_NEW, {
          new: {
            id: item.id,
            slug: item.slug,
          },
          slug: item.slug,
        });
        for (var i = 0; i < this.list.length; i++) {
          if (this.list[i].id === item.id) {
            this.list.splice(i, 1);
          }
        }
      }
    },
    getDetailNewsByCategories(id){
      for (let i = 0 ; i < this.list.length; i++){
        if (this.list[i].categories_id !== id){
          this.list.splice(i, 1);
        }
      }
      this.dialog = false;
      this.btnGetList = true ;
    },

    sanitizeTitle(title) {
      // Change to lower case
      var str = title.toLowerCase();
      str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
      str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
      str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
      str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
      str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
      str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
      str = str.replace(/đ/g, "d");
      // Some system encode vietnamese combining accent as individual utf-8 characters
      str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // Huyền sắc hỏi ngã nặng
      str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
      // Trim the last whitespace
      str = str.replace(/\s*$/g, "");
      // Change whitespace to "-"
      str = str.replace(/\s+/g, "-");

      return str;
    },
  }
};
</script>

<style>
.custom-select {
  width: 150px !important;
}
.new-list .v-data-table .v-data-table__wrapper table tbody td img {
  margin-right: 10px;
}

.new-list .v-data-table .v-data-table__wrapper table thead {
  background: #e6e6e6;
}

.new-list .create-btn {
  text-align: end;
  margin: 26px 2%;
}

.new-list .create-btn .v-btn {
  border-radius: 20px;
  height: 30px;
  min-width: 114px;
  width: 114px;
}

.new-list .create-btn .theme--light {
  background: black !important;
  color: white;
}
</style>
